/* body{font-family: 'Raleway', sans-serif;} */
.header{box-shadow: 0 4px 14px rgb(0 0 0 / 15%);background-color: #fff;
    padding: 20px;
    z-index: 100;
    position: relative;
    width: 100%;
    box-sizing: border-box;
   
    flex-direction: row;
    align-items: center;
    justify-content: space-between;}
    .background{
        background: url(https://enrollment-app.azurewebsites.net/static/media/somaderm-waterline.d12548e6.jpg) top;background-size: cover;background-attachment: fixed;height: 100%;
        width: 100%;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        min-height: 100vh;
        transition: all .2s ease;
        justify-content: flex-start;
        position: relative;
      }
      .background::before{content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: hsla(0,0%,100%,.5);
        z-index: 0;}
      .center-box{margin: 60px;width: 90%;
        max-width: 800px;
        border-radius: 17px;
        box-shadow: 0 0 25px rgb(0 0 0 / 20%);
        background-color: #fff;
        padding-bottom: 20px;z-index: 99;}
.main-content{
  padding: 60px 40px 60px;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
      margin: 56px;
      margin-top: 40px;
      border-radius: 20px;
}
.center{text-align: center;}
.enable-btn{
        background-color: #007ec3;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        border-radius: 4px;
        border: 0;
        margin-top: 20px;
        display: inline-block;
        transition: background-color .1s linear;
        cursor: pointer;}
.ant-input{
  height: 36px;
      outline: none;
      border-radius: 4px;
      border: 1px solid #e5e7eb;
      box-shadow: 0 1px 2px rgb(31 41 55 / 8%);
}
.ant-col div{   
  }
  .ant-select-selection-item{overflow: visible!important;}
  .ant-select-selection-placeholder{overflow: visible!important;}
  .ant-form-item-label > label{width: 168px;}
  .ant-form label{width:170px;font-weight: 700;}
  .headertitle{    text-align: center;
    padding: 40px 0px;
    font-size: 36px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin: 0px;
    padding-bottom: 0px;
  }
    /* .ant-input{width: 55%;} */
    .enable-btn{
      height: 44px;
    }
    .ant-select{
      /* padding-left: 83px; */
      min-width: 100px;
    }
    .CreditCardDetails .ant-row{
      margin-bottom: 24px;
          /* align-items: baseline; */
    }

    .form-control{
      height: 36px;
      outline: none;
      border-radius: 4px;
      border: 1px solid #e5e7eb;
      box-shadow: 0 1px 2px rgb(31 41 55 / 8%);
    }

    .cardTypes{
      border-radius: 10px;
      padding: 8px 25px;
      background: #00000059;
      color: white;
      font-weight: 600;
      min-width: 100px;
      text-align: center;
      cursor: pointer;
      border: none;
    }


.NMI #ccnumber ,.NMI #ccexp ,.NMI #cvv , #ccnumber_field , #ccexp_field , #cvv_field , #cname_field{
  opacity: 1;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  background-color: rgb(255, 255, 255);
  background-image: none;
  transition: all 0.3s ease 0s;
  padding: 2px 11px;
  color: rgba(0, 0, 0, 0.85)  !important;
  font-size: 14px;
  line-height: 1.5715;
  height: 36px !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px  !important;
  box-shadow: 0 1px 2px rgb(31 41 55 / 8%) !important;
}

#cnumber input ,#cexp input, #ccvv input, #cname input{
  height: 36px !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px  !important;
}

.add-card{
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px  !important;
  padding: 10px 11px  !important;
  color: rgba(0, 0, 0, 0.85)  !important;
  font-size: 14px  !important;
  line-height: 1.5715  !important;
  height: 36px !important;
  box-shadow: 0 1px 2px rgb(31 41 55 / 8%) !important;
}

.ant-select-selector{
  height: 36px !important;
  padding: 2px 11px  !important;
  color: rgba(0, 0, 0, 0.85)  !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 1px 2px rgb(31 41 55 / 8%) !important;
}

.requiredField , .input-label-error{
  color: #ff4d4f !important;
    font-size: 14px !important;
    margin-bottom: 0px;
}

.ant-form label{
  font-weight: 400 !important;
}

.colDiv{
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.labelCol{
  align-items: center;
  display: flex !important;
  height: 36px;
}

.ant-form-item-label{
  text-align: left !important;
}

.ant-input:focus , .ant-input:hover {
  box-shadow: none !important;
  border-color: lightslategrey !important;
}


.paymentSelection button{
  height: 50px !important;
  line-height: 0 !important;
  color: #747679 !important;
  padding: 10px 30px !important;
  border-radius: 5px !important;
  border: 2px solid transparent !important;
  transform: none !important;
  box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%) !important;
}

.paymentSelection button:focus , .paymentSelection button:hover{
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%) !important;
  outline: none !important;
}

.paymentSelection .selectedButton{
  outline: none !important;
  border: 2px solid #7AB800 !important;
}

.marginBotton16{
  margin-bottom: 16px;
}